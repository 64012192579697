.Toastify__toast--success {
  background-color: #26C485; }

.Toastify__toast--error {
  background-color: #F03A47; }

.Toastify__toast--info {
  background-color: #A3E7FC; }

.Toastify__toast-container {
  text-align: center; }

.Toastify__toast {
  min-height: 0px; }

@media (min-width: 1025px) {
  .Toastify__toast-container {
    width: 40%; }
  .Toastify__toast-container--top-center {
    margin-left: -20%; } }

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none; } }

/* This is critical for modals so that dropdowns naturally overflow */
.modal-open .modal {
  overflow-y: visible; }

.modal-card .card-body {
  overflow-y: visible; }

.modal-card .full-height-card {
  max-height: 100%;
  height: 100%; }
